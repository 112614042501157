// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";

import "trix";
import "@rails/actiontext";
Trix.config.blockAttributes.heading1.tagName = "h2";

import "chartkick/chart.js";

// fixes https://github.com/ankane/chartkick/issues/625
document.addEventListener("turbo:morph-element", (event) => {
  const { newElement } = event.detail;
  if (newElement.tagName == "SCRIPT") {
    window.eval(newElement.innerText);
  }
});

import LocalTime from "local-time";
LocalTime.start();

document.addEventListener("turbo:morph", () => {
  LocalTime.run();
});

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "./controllers";
